import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Facultad from "../components/facultad"
import Footer from "../components/footer"

const FacultadPage = () => (
  <Layout>
    <Seo title="Facultad" />
    <Hero title="Asignaturas" subtitle="Facultad" type="FACULTAD" />
    <Facultad />
    <Footer />
  </Layout>
)

export default FacultadPage
